<template>
  <div>
    <a-card title="从业人员">
      <a-row :gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px;">
          <a-input v-model="searchForm.search" placeholder="编号, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px;">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right;">
          <a-button type="primary" icon="plus" style="margin: 0 8px;" @click="openFormModal(form)">新增</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
                 @change="tableChange">
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{value ? '激活' : '冻结'}}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="eye" size="small" @click="openFormModal1(item)">查看</a-button>
              <a-button icon="edit" size="small" @click="openFormModal(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <form-modal v-model="visible" :form="targetItem" :suppliersClassificationOptions="suppliersClassificationOptions" @create="create" @update="update" />
    <form-modal1 v-model="visible1" :form="targetItem1" :suppliersClassificationOptions="suppliersClassificationOptions1" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div>
        <a-spin style="margin-right: 12px;" />正在导入中, 请等待...
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { employeeViewList, employeeViewDel } from "@/api/enterprise";

  export default {
    name: 'Warehouse',
    components: {
      FormModal: () => import('./FormModal.vue'),
      FormModal1: () => import('./FormModal1.vue')
    },
    data() {
      return {
        columns: [
          {
            title: "姓名",
            dataIndex: "others.name"
          },
          {
            title: "性别",
            dataIndex: "others.sex"
          },
          {
            title: "岗位",
            dataIndex: "others.post"
          },
          {
            title: "状态",
            dataIndex: "others.status"
          },
          {
            title: "手机号",
            dataIndex: "others.phone"
          },
          {
            title: "健康证编号",
            dataIndex: "others.healthNumber"
          },
          {
            title: "培训证编号",
            dataIndex: "others.cultivateNumber"
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: '156px'
          },
        ],
        searchForm: { search: '', page: 1, page_size: 16 },
        pagination: { current: 1, total: 0, pageSize: 50 },
        loading: false,
        items: [],
        suppliersClassificationOptions: [],
        suppliersClassificationOptions1: [],
        visible: false,
        targetItem: { others: { credit_code: {}, organization_code: {}, license_number: {} } },
        visible1: false,
        targetItem1: { others: { credit_code: {}, organization_code: {}, license_number: {} } },
        form: { others: { credit_code: {}, organization_code: {}, license_number: {} }  },
        importLoading: false,
      };
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        employeeViewList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.data;
        }).finally(() => {
          this.loading = false;
        });
      },
      create() {
        this.list();
      },
      update() {
        this.list();
      },
      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      openFormModal(item) {
        this.targetItem = { ...item };
        this.visible = true;
      },
      openFormModal1(item) {
        this.targetItem1 = { ...item };
        this.visible1 = true;
      },
      destroy(id) {
        employeeViewDel({ id }).then(() => {
          this.items.splice(this.items.findIndex(item => item.id == id), 1);
          this.$message.success('删除成功');
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>
